import { useQuery } from 'react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { api } from '../domain/api'
import {
  formatResponse,
  GuestFeedbackQuery,
  GuestFeedbackCombinedResponse
} from '../domain/guestFeedbackDetailsContext'
import { useGuestFeedbackUrlData } from './useGuestFeedbackUrlData'
import { MENU_ITEM_FEATURE_FLAG } from './constants'
import { AxiosResponse } from 'axios'

interface UseQueryGuestFeedbackProps {
  enabled: boolean
  onLoadSuccess: (data: GuestFeedbackCombinedResponse) => void
  onLoadError: () => void
}

export const useQueryGuestFeedback = ({
  enabled,
  onLoadSuccess,
  onLoadError
}: UseQueryGuestFeedbackProps): GuestFeedbackQuery => {
  const flagData = useFlags()
  const guestFeedbackUrlData = useGuestFeedbackUrlData()
  const { isBadUrlData } = guestFeedbackUrlData

  const { isLoading, isError, data, refetch } = useQuery(
    [
      'queryGuestFeedbackDetails',
      guestFeedbackUrlData,
      flagData?.[MENU_ITEM_FEATURE_FLAG]
    ],
    () =>
      api.getGuestFeedback({
        ...guestFeedbackUrlData,
        showOrderItemFeedback: flagData?.[MENU_ITEM_FEATURE_FLAG]
      }),
    {
      enabled: enabled && !isBadUrlData && !!flagData,
      cacheTime: 0,
      staleTime: Infinity,
      onSuccess: (
        requestData: AxiosResponse<GuestFeedbackCombinedResponse>
      ) => {
        onLoadSuccess(formatResponse(requestData?.data))
      },
      onError: onLoadError
    }
  )

  return {
    isLoading,
    isError,
    refetch,
    ...formatResponse(data?.data)
  }
}
