import * as React from 'react'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { isIntl } from '../../domain/international'

export const Disclaimers = () => {
  const { intlProps } = useGuestFeedbackContext()

  if (isIntl(intlProps)) {
    return (
      <p
        data-testid='intl-disclaimer'
        className='p-4 text-center type-subhead text-secondary'
      >
        The information you provide will be processed pursuant to Toast's{' '}
        <a
          href='https://pos.toasttab.com/terms-of-service'
          target='_blank'
          className='text-link hover:text-link-hover hover:underline'
          rel='noreferrer'
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href='https://pos.toasttab.com/privacy'
          target='_blank'
          className='text-link hover:text-link-hover hover:underline'
          rel='noreferrer'
        >
          Privacy Statement
        </a>
        .
      </p>
    )
  }

  return (
    <div className='space-y-4 p-4'>
      <p
        className='text-center type-subhead text-secondary'
        data-testid='contact-disclaimer'
      >
        The restaurant tracks feedback and may reach out using the contact info
        you previously provided.
      </p>
    </div>
  )
}
