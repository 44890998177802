import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { GuestFeedback } from '../GuestFeedback/GuestFeedback'
import { useLdConfig } from '../../configs/useLdConfig'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

export const App = () => {
  const ldProvider = useLdConfig()

  return (
    <QueryClientProvider client={queryClient}>
      <LDProvider {...ldProvider}>
        <SnackBarProvider>
          <BrowserRouter basename='/'>
            <Routes>
              <Route path='*' element={<GuestFeedback />} />
            </Routes>
          </BrowserRouter>
        </SnackBarProvider>
      </LDProvider>
    </QueryClientProvider>
  )
}
