import * as React from 'react'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { Button } from '@toasttab/buffet-pui-buttons'

export const SubmitButton = () => {
  const { saveFeedback, saving, state } = useGuestFeedbackContext()
  const { feedbackItems, feedbackFreeFormText, feedbackReasons } = state

  const invalidForm =
    Object.keys(feedbackItems).length === 0 &&
    feedbackFreeFormText === '' &&
    feedbackReasons.length === 0

  return (
    <Button
      className='w-full mt-4'
      onClick={saveFeedback}
      disabled={saving || invalidForm}
    >
      {saving ? 'Sending...' : 'Share feedback'}
    </Button>
  )
}
